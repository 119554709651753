/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useQuery } from 'react-query';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Section from './Section';
import {
  GetTrendingPostsResult,
  Post,
  PostParentKey,
} from 'common/interfaces/api';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import PostComponent from 'components/Post';

import styles from './TrendingPosts.module.scss';
import { PostsLoader } from 'components/common/PreloadContentLoader';

export type PostProps = {
  post: Post;
};

export default function TrendingPosts({
  setSymbolId,
}: {
  setSymbolId: (n: number) => void;
}): JSX.Element {
  const isAuthInit = useGlobalStore((state) => state.isAuthInit);
  const currentAuth = useGlobalStore((state) => state.currentAuth);
  const showLogin = useGlobalStore((state) => state.showLogin);
  const [isLimit, setIsLimit] = useState(true);
  const shouldRefetchPosts = useStore((state) => state.shouldRefetchPosts);
  const handleShowMoreClick = () => {
    if (!currentAuth) {
      showLogin({});
      return;
    }
    setIsLimit(false);
  };

  const { data, refetch, isFetched } = useQuery(
    'getTrendingPosts',
    async () => {
      const [timelineResponse, trendingResponse] = await Promise.all([
        axios.get('/feeds/home-timeline', { params: { page: 1 } }),
        axios.get('/feeds/trending', { params: { page: 1 } }),
      ]);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const symbolId = timelineResponse?.data?.data?.sections[3]?.vote?.id;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const homeTimelinePosts = (timelineResponse?.data?.data?.sections[1]
        ?.trending_posts || []) as Post[];
      const trendingPosts =
        (trendingResponse?.data as GetTrendingPostsResult)?.data?.posts || [];
      const homeTimeLinesPostIds = homeTimelinePosts.map((p) => p.id);
      const checkTrendingPosts = trendingPosts.filter(
        (p) => !homeTimeLinesPostIds.includes(p.id)
      );
      return {
        posts: homeTimelinePosts.concat(checkTrendingPosts),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        symbolId: symbolId,
      };
    },
    {
      enabled: isAuthInit,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        setSymbolId(data?.symbolId);
      },
    }
  );

  useEffect(() => {
    if (shouldRefetchPosts) {
      void refetch();
    }
  }, [refetch, shouldRefetchPosts]);

  // paging
  const posts = data?.posts || [];
  const trendingPosts: JSX.Element[] = (
    isLimit ? posts.slice(0, 7) : posts
  ).map((post, index) => (
    <div key={`${post?.id}-${index}`}>
      <div className={styles.trendingPost}>
        <PostComponent
          post={post}
          parent={PostParentKey.TrendingPosts}
          hasFollowBtn
        />
      </div>
    </div>
  ));
  return (
    <Section text="" backgroundColor="transparent">
      <div className={styles.trendingPosts}>
        {isFetched ? trendingPosts : <PostsLoader />}
      </div>
      {isLimit && posts.length > 0 && (
        <div className={styles.more}>
          <button onClick={() => handleShowMoreClick()}>
            トレンドをもっと見る
          </button>
        </div>
      )}
    </Section>
  );
}
